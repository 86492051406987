import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ApolloClientOptions, InMemoryCache } from "@apollo/client/core";
import { APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { environment } from "src/environments/environment";

const uri = environment.api.graphqlUrl;

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
    return {
        link: httpLink.create({ uri }),
        cache: new InMemoryCache({
            addTypename: false,
        }),
    };
}

@NgModule({
    declarations: [],
    imports: [ CommonModule ],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [ HttpLink ],
        },
    ],
})
export class ApolloModule {}
