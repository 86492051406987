import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";

export const LoggedGuard = () => {
  const keycloakService = inject(KeycloakService);
  const router = inject(Router);

  const isLogged = keycloakService.isLoggedIn();
  if (!isLogged) keycloakService.login({
    redirectUri: environment.keycloak.redirectUrl + router.url,
  });
  return isLogged;
};
