import { inject, Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { map } from "rxjs";

type Version = {
  version: string;
  buildTime: Date;
  revision: string;
}

@Injectable({
  providedIn: "root"
})
export class VersionService {
  private readonly apollo = inject(Apollo);

  logVersion() {
    this.apollo.query<{ version: Version }>({
      query: gql`
        query version {
          version {
            version
            buildTime
            revision
            __typename
          }
        }
      `
    }).pipe(
        map(({ data }) => data.version),
    ).subscribe({
      next: (version: Version) => console.log(version),
    });
  }
}
