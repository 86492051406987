import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ActivatedRoute, Router, UrlSegment } from "@angular/router";
import { AuthService } from "@auth/services/auth.service";
import { KeycloakService } from "keycloak-angular";
import { catchError, EMPTY, from, map, Observable, switchMap } from "rxjs";
import { environment } from "src/environments/environment";

const AUTHORIZATION = {
  basicPrefix: "Basic",
  bearerPrefix: "Bearer",
  headerName: "Authorization",
};

type beforeFunc = (
  request: HttpRequest<unknown>,
) => Observable<HttpRequest<unknown>>;
type prepareFunc = (request: HttpRequest<unknown>) => Promise<HttpRequest<unknown>>;
type onErrorFunc = (
  error: HttpRequest<unknown>,
  caught: Observable<HttpEvent<any>>,
  request: HttpRequest<unknown>,
  next: HttpHandler,
) => Observable<HttpEvent<any>>;
type afterFunc = (event: HttpEvent<unknown>) => Observable<HttpEvent<unknown>>;

type Handlers = {
  before?: beforeFunc;
  prepareRequest?: prepareFunc;
  onError?: { [key: number]: onErrorFunc };
  after?: afterFunc;
};
type EnpointsHandlers = {
  [endpointUrl: string]: Handlers;
};

const BLACK_LIST_ORIGIN_REDIRECT: string[] = [
  "backend-not-available",
  "/backend-not-available",
];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly authService = inject(AuthService);
  private readonly keycloakService = inject(KeycloakService);
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith("ws://")) {
      console.log("websocket");
      return next.handle(request);
    }
    if (request.url.startsWith(environment.api.graphqlUrl)
        || request.url.startsWith(environment.api.restUrl)
        || request.url.startsWith(environment.api.wsGraphqlURL)
        || request.url.startsWith(environment.api.downloadEndpoint)
        || request.url.startsWith(environment.api.staticResourcesUrl)
    ) {
      const pathOrigin = this.getCurrentRoute();
      return from(this.keycloakService.getToken()).pipe(
          map(accessToken => this.prepareAuthenticatedRequest(request, accessToken)),
          switchMap(req => next.handle(req)),
          catchError(err => {
            if (err.status === 403) {
              this.router.navigate([ "/not-authorized-app-access" ]);
            } else if (err.status === 0 && !BLACK_LIST_ORIGIN_REDIRECT.includes(pathOrigin)) {
              this.router.navigate([ "/backend-not-available" ], { queryParams: { pathOrigin } });
              throw new Error("Loose connection with backend");
            }
            throw err;
          }),
      );
    }
    else {
      return next.handle(request);
    }
  }

  private getCurrentRoute(): string {
    let route = this.route;
    const paths: UrlSegment[] = [];
    paths.push(...route.snapshot.url);
    while(route.firstChild) {
      route = route.firstChild;
      paths.push(...route.snapshot.url);
    }
    return `/${  paths.join("/")}`;
  }

  // Before Functions

  private prepareAuthenticatedRequest(
    req: HttpRequest<unknown>,
    accessToken: string,
  ): HttpRequest<unknown> {
    const token = `${AUTHORIZATION.bearerPrefix} ${accessToken}`;
    return req.clone({
      headers: req.headers.append(AUTHORIZATION.headerName, token),
    });
  }

  // onError Functions

  private logout(caught: Observable<unknown>) {
    this.authService.logout();
    console.warn("401 - disconnected");
    return EMPTY;
  }
  //After functions
}
