import { Location } from "@angular/common";
import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "@auth/services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastService } from "@shared/services/toast.service";
import { tap } from "rxjs";

export const RoleGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const authService = inject(AuthService);
  const toastService = inject(ToastService);
  const translateService = inject(TranslateService);
  const router = inject(Router);
  const location = inject(Location);

  const roles: string[] = route.data["roles"];
  return authService.hasRoles(roles).pipe(
    tap((hasRoles) => {
      if (!hasRoles) {
        translateService.get("shared.error.access-denied").subscribe({
          next: (msg) => toastService.error(msg),
        });
        if (router.navigated) {
          location.back();
        } else {
          router.navigate([]);
        }
      }
    }),
  );
};
