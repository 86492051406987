import { registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import localeFr from "@angular/common/locales/fr";
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, LOCALE_ID } from "@angular/core";
import { MAT_LUXON_DATE_ADAPTER_OPTIONS } from "@angular/material-luxon-adapter";
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter } from "@angular/router";
import { ApolloModule } from "@app/apollo/apollo.module";
import { AppRoutes } from "@app/app.routes";
import { AuthInterceptor } from "@auth/interceptors/auth.interceptor";
import { TYPES } from "@models/data/type-resolver";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { UBI_TYPES } from "@shared/injectors/ubi-types.injector";
import { CustomMatPaginatorIntl } from "@shared/translations/CustomMatPaginatorIntl";
import { Apollo } from "apollo-angular";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { MarkdownModule } from "ngx-markdown";
import { environment } from "src/environments/environment";
import { CustomLuxonAdapter } from "./config/custom-luxon-adapter";

registerLocaleData(localeFr);

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
      keycloak.init({
        config: {
          url: environment.keycloak.url,
          realm: environment.keycloak.realm,
          clientId: environment.keycloak.clientId,
        },
        initOptions: {
          onLoad: "check-sso",
          pkceMethod: "S256",
          silentCheckSsoRedirectUri:
              `${window.location.origin + environment.baseHref  }/assets/silent-check-sso.html`
        }
      });
}

export const AppConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [ HttpClient ],
        },
      }),
      ApolloModule,
      MatNativeDateModule,
      MarkdownModule.forRoot(),
      MatSnackBarModule,
      MatDialogModule,
    ),
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
    {
      provide: LOCALE_ID,
      useValue: "fr-FR",
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: "fr-FR",
    },
    {
      provide: DateAdapter,
      useClass: CustomLuxonAdapter,
      deps: [ MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS ],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    Apollo,
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideRouter(AppRoutes),
    MatSnackBarModule,
    KeycloakAngularModule,
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [ KeycloakService ],
    },
    {
      provide: UBI_TYPES,
      useValue: TYPES,
    }
  ],
};
