import { Injectable } from "@angular/core";
import { LuxonDateAdapter } from "@angular/material-luxon-adapter";
import { DEFAULT_LANGUAGE_KEY } from "@shared/services/languages.service";
import { DateTime, DateTimeFormatOptions } from "luxon";
import { AVAILABLE_LOCALES, findFirstDayOfWeek } from "./locales";

@Injectable()
export class CustomLuxonAdapter extends LuxonDateAdapter {
  override getFirstDayOfWeek(): number {
    const locale =
      localStorage.getItem(DEFAULT_LANGUAGE_KEY) ?? AVAILABLE_LOCALES[0];
    return findFirstDayOfWeek(locale);
  }

  override format(date: DateTime, displayFormat: string): string {
    if (!this.isValid(date)) {
      throw Error("LuxonDateAdapter: Cannot format invalid date.");
    }
    return date
      .setLocale(this.locale)
      .toLocaleString(displayFormat as DateTimeFormatOptions);
  }
}
