import { Routes } from "@angular/router";
import { LoggedGuard } from "@auth/guards/LoggedGuard";
import { RoleGuard } from "@auth/guards/role.guard";

export const AppRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "workspace",
        loadChildren: () =>
            import("@workspace/workspace.routes").then((r) => r.WorkspaceRoutes),
      },
      {
        path: "administration",
        loadChildren: () =>
            import("@administration/administration.routes").then(
                (r) => r.AdministrationRoutes,
            ),
        canActivate: [ RoleGuard ],
        data: { roles: [ "Administrator" ] },
      },
      {
        path: "user-workspace",
        loadChildren: () =>
            import("@user-workspace/user-workspace.routes").then(
                (r) => r.UserWorkspaceRoutes,
            ),
      },
      {
        path: "not-authorized-app-access",
        loadComponent: () => import("@app/global-routes/not-authorized-app-access/not-authorized-app-access.component"),
      },
      {
        path: "backend-not-available",
        loadComponent: () => import("@app/global-routes/backend-not-available/backend-not-available.component"),
      },
      {
        path: "**",
        redirectTo: "workspace",
      },
    ],
    canActivate: [ LoggedGuard ]
  },
];
